<template>
  <div class="modal-card" style="width: auto">
    <div class="modal-card-body">
      <div class="level">
        <div class="level-item">
          <gmap-map :center="location" :zoom="12" style="width:100%; height: 400px;">
            <gmap-marker :position="location"></gmap-marker>
          </gmap-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      center: {}
    }
  },
  props: [
    'location'
  ]
}
</script>
