<template>
  <div class="slideout" :class="{active: preview}">
    <div class="panel">
      <div>
        <a type="button" class="delete is-larg" @click="$parent.previewPost">close</a>
      </div>
      <div class="content">
        <section class="section">
          <div id="preview" class="image" v-if="post.postImage">
            <img id="preview-image" class="main-image" :src="XbortGetMedia(post.postImage, 'large')" />
          </div>
          <h1 class="is-size-1-desktop">{{ post.postTitle }}</h1>
          <div class="level">
            <div class="levl-right">
              <div class="level-item">
                <p class="subtitle">{{ post.postCategory.category_name }}</p>
              </div>
            </div>
            <div class="levl-left">
              <div class="level-item">
                <span class="tag is-white">{{ post.postDate | formatDate }} </span>
              </div>
              <div class="level-item">
                <figure class="image is-48x48 image-cropper">
                  <img :src="XbortGetUserImage(profiles, post.postAuthor, 'small')" />
                </figure>
              </div>
              <div class="level-item">
                <p>{{ XbortGetUserName(profiles, post.postAuthor) }}</p>
              </div>
            </div>
          </div>
          <hr />
          <p v-if="post.postArabicTitle"><strong>الاسم العربي: </strong><span v-html="post.postArabicTitle"></span></p>
          <p v-if="post.postScientificTitle"><strong>الاسم العلمي: </strong><span style="font-style: italic;" dir="ltr" v-html="post.postScientificTitle"></span></p>
          <p v-if="post.postLocalTitle"><strong>الاسم المحلي: </strong><span v-html="post.postLocalTitle"></span></p>
          <p v-if="post.postPlace"><strong>اسم الموقع: </strong><span v-html="post.postPlace"></span></p>
          <hr>
          <p v-if="post.postContent" v-html="post.postContent"></p>
        </section>
        <section class="section">
          <div id="lightgallery" class="columns is-multiline lightgallery gallery">
            <a v-for="(image, key) in post.postMedia" class="column is-2" :key="key" :href="XbortGetMedia(image, 'large')" dir="ltr">
              <img :src="XbortGetMedia(image, 'small')" />
            </a>
          </div>
        </section>
        <section>
          <post-map :location="post.postLocation" />
        </section>
        <section class="section" v-if="post.postTags.length > 0">
          <b-taglist>
            <b-tag type="is-primary" v-for="(value, key) in post.postTags" :key="key">{{ value.tag_name }}</b-tag>
          </b-taglist>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import PostMap from '@/components/PostMap'
import 'lightgallery.js/dist/css/lightgallery.css'
import 'lightgallery.js/dist/js/lightgallery'

export default {
  updated () {
    window.lightGallery(document.getElementById('lightgallery'))
    this.getImageSize()
  },
  props: [
    'post',
    'preview',
    'profiles'
  ],
  components: {
    PostMap
  },
  methods: {
    getImageSize: function () {
      let preview = document.getElementById('preview')
      let previewImage = document.getElementById('preview-image')
      let image = new Image()
      if (this.$parent.postPreview) {
        imagesLoaded(previewImage, function () {
          image.src = previewImage.getAttribute('src')
          if (image.height > image.width) {
            preview.classList.add('post-image')
          } else {
            preview.classList.remove('post-image')
          }
        })
      }
    }
  }
}
</script>

<style>
.post-image {
  position: relative;
  height: 450px;
  overflow: hidden;
  width: 100%;
}
.post-image img {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  object-fit: cover;
}
.image-style-align-left {
  float: left;
}
.image-style-align-right {
  float: right;
}
.content figure:not(:last-child) {
    margin-bottom: 1em;
}
.content figure:not(:first-child) {
    margin-bottom: 1em;
}
.image-cropper {
  width: 55px;
  height: 55px;
  position: relative;
  overflow: hidden;
  border-radius: 35%;
  /* border: 1px solid white; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image-cropper img {
  display: inline;
  margin: 0 auto;
}
</style>
